<template>
  <div>
    <the-section title="Mieszkania" container>
      <div class="row">
        <div class="col-lg-3">
          <filters></filters>
        </div>
        <div class="col-lg-9" id="filtered-results">
          <results></results>
          <div class="pagination" v-if="results.totalPages > 1">
            <span v-if="currentPage > 0" @click="changePage('-')">
              <fa-icon icon="angle-left"></fa-icon>
            </span>
            <span
              :class="{
                active:
                  result-1 == results.currentPage,
              }"
              v-for="result in results.totalPages"
              @click="changePage(result-1)"
              :key="result"
              >{{ result }}</span
            >
            <span v-if="currentPage < (results.totalPages-1)" @click="changePage('+')">
               <fa-icon icon="angle-right"></fa-icon>
            </span>
          </div>
        </div>
      </div>
    </the-section>
  </div>
</template>

<script>
import Filters from "../components/apartmentpage/Filters.vue";
import Results from "../components/apartmentpage/Results.vue";

export default {
  data() {
    return {
      currentPage:0
    }
  },
  components: {
    Filters,
    Results,
  },
  computed: {
    results() {
      return this.$store.getters["apartments/getFilteredResults"];
    },
  },
  methods: {
    async changePage(page) {
      if(page == '+') this.currentPage++;
      else if(page == '-') this.currentPage--;
      else if(page == this.results.currentPage) return;
      else this.currentPage = page;
      const fetchedQuery = `&${this.$store.getters['apartments/getFilterLastQuery'].join("&")}`;
      await this.$store.dispatch('apartments/loadFilteredResults',`?page=${this.currentPage}${fetchedQuery}`);
      document.getElementById('filtered-results').scrollIntoView();
      this.$store.commit('apartments/setCurrentPage', this.currentPage);
    }
  },
  async beforeMount() {
    await this.$store.dispatch("cities/loadResults");
    await this.$store.dispatch("apartments/loadFilteredResults", null);
  },
};
</script>

<style scoped>
.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.8rem;
  margin: -0.5rem;
  margin-top: 1rem;
  cursor: pointer;
}

.pagination span {
  width: 4rem;
  height: 4rem;
  display: grid;
  place-content: center;
  margin: 0.5rem;
  color: black;
}

.pagination span.active {
  font-weight: bold;
  cursor: default;
  color: #c33b07;
}
</style>