<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-12 col-lg-6">
        <iframe height="355" width="420" class="removeBorder" src="https://www.youtube.com/embed/KHllBsPQCyc"></iframe> <!--width="420" height="315" -->
      </div>
      <div class="col-12 col-lg-6 px-5 yt-video-description">
        <h3>Technologia AR</h3>
        <p>
          Zespół domów szeregowych realizowany jest w systemach do szybkiej budowy <span class="bold">Ytong Jumbo</span> i <span class="bold">Silka Tempo.</span> 
          W połączeniu z mechanizacją prac budowlanych (zastosowaniem specjalnych miniżurawi) prace przebiegają <span class="bold">od 25 do 50%</span> szybciej niż w tradycyjnych technologiach murowych.
        </p>
        <p>
          Podczas budowy <span class="bold">korzystamy z technologi rozszerzonej rzeczywistości (AR).</span> 
          Operator miniżurawia dzięki nałożeniu okularów AR, nie musi zaglądać do planu montażowego –  <span class="underline">model 3D nałożony jest na obraz rzeczywisty.</span> 
          Technologia AR i <span class="underline">okulary Microsoft HoloLens 2</span> pozwalają również szybko skontrolować dokładność wykonania zrealizowanych już ścian czy stropów.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
h3 {
  font-size: 2.1rem;
}

iframe {
  border:none;
}

p {
  font-size: 1.7rem;
  margin-bottom: 1rem;
}

span.bold {
  font-weight: 700;
}

span.underline {
  text-decoration: underline;
}

.yt-video-description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>