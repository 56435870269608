<template>
  <div id="customSelect" :style="setPosition">
    <div class="position" @click="selectCity(city)" v-for="city in cities" :key="city.id">
      <the-badge>{{ city.investmentsNumber }}</the-badge>{{ city.name }}
    </div>
    <div v-if="!cities.length" class="position">
      Brak miast
    </div>
  </div>
  <div id="cheat" @click="hideSelect"></div>
</template>

<script>
export default {
  props: ["x", "y", "display"],
  emits: ['hideSelect','selected'],
  data() {
    return {
      cities: {}
    }
  },
  mounted() {
    this.cities = this.$store.getters['cities/foundResults'];
  },
  computed: {
    setPosition() {
      let x;
      if(window.innerWidth <= 768) x = 0;
      else x = this.x;
      return `top: ${this.y}px; left: ${x}px`;
    },
  },
  methods: {
    hideSelect() {
      this.$emit('hideSelect');
    },
    selectCity(city) {
      this.hideSelect();
      this.$emit('selected',city)
    }
  },
};
</script>

<style scoped>
#customSelect {
  position: absolute;
  top: 250px;
  left: 0px;
  z-index: 1;
  background: #333;
  max-height: 180px;
  height: auto;
  overflow-y: auto;
  width: 100vw;
  display: block;
}
#cheat {
  position:fixed;
  top:0;
  left:0;
  display: block;
  width: 100%;height: 100%;
}

@media (min-width: 768px) {
  #customSelect {
    width:350px;
  }
}

#customSelect .position {
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  font-size: 1.6rem;
  color: white;
  cursor: pointer;
}
</style>