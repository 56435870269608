<template>
  <h3>
    <slot></slot>
  </h3>
</template>

<script>
export default {

}
</script>

<style scoped>
h3 {
  text-transform: uppercase;
  color: white;
  width: 100%;
  height: 50px;
  position: relative;
  background: #c33b07;
  font-size: 2.5rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 8rem;
  padding-bottom: 1rem;
}

h3::after {
  content: '';
  width: 600px;
  max-width: 100vw;
  height: 50px;
  display: block;
  background: #c33b07;
  position: absolute;
  top: 45px;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

@media (min-width: 768px) {
  h3 {
    height: 60px;
  }

  h3::after {
    height: 60px;
    top:50px;
  }
}
</style>