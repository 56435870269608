<template>
    <div class="container pt-5">
        <div class="d-flex flex-column flex-lg-row justify-content-around align-items-center">
            <img src="../../assets/img/biuro-rachunkowe.jpg" alt="TBC Development" width="350" />
            <img src="../../assets/img/wizytowka_skrzyp.jpg" alt="Skrzyp" width="350" />
        </div>
    </div>
</template>
  
<script>
export default {};
</script>
  
