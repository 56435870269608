<template>
  <div class="main-image" @mouseenter="showDesc" @mouseleave="hideDesc">
    <slot></slot>
    <transition v-if="title || description" name="desc">
      <div class="main-image-desc">
        <p>{{title}}</p>
        <p>{{description}}</p>
      </div>
    </transition>
    <transition name="desc">
      <div class="main-image-bigger" @click="showModal = true" v-show="descShown">
        <fa-icon icon="search"></fa-icon>
      </div>
    </transition>
  </div>
  <div v-if="showModal" class="full-screen" @click="showModal = false">
    <fa-icon icon="times"></fa-icon>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["title", "description"],
  data() {
    return {
      descShown: false,
      showModal: false,
    };
  },
  methods: {
    showDesc() {
      return (this.descShown = true);
    },
    hideDesc() {
      return (this.descShown = false);
    },
  },
};
</script>

<style scoped>
.main-image {
  position: relative;
  overflow: hidden;
}

.main-image :deep() img {
  border-left: 3px solid #c33b07;
  border-top: 3px solid #c33b07;
  height: 450px;
  object-fit: cover;
  width: 100%;
}

.full-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top:0;
  left:0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: #000000aa;
  cursor: pointer;
  box-shadow: 4px 4px 4px rgba(0,0,0,.15);
  z-index: 10;
}

.full-screen :deep() svg {
  color:white;
  filter: drop-shadow(4px 4px 4px rgba(0,0,0,.6));
  position: absolute;
  top:20px;
  right:45px;
  font-size: 4rem;
}

.full-screen :deep() img {
  max-width: 100%;
  max-height: 100%;
  border:3px solid white;
  height: auto;
  object-fit: scale-down;
  box-shadow: 4px 4px 4px rgba(0,0,0,.15);
}

.main-image-bigger {
  position: absolute;
  transform-origin: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  top:0;
  left:0;
  color:white;
  font-size: 5.5rem;
  filter: drop-shadow(4px 4px 4px rgba(0,0,0,.6));
}

.main-image-desc {
  position: absolute;
  bottom: 5px;
  left: 3px;
  width: 100%;
  height: auto;
  padding: 3rem;
  color: white;
  font-size: 1.6rem;
  background: rgba(0, 0, 0, 0.6);
}

.desc-enter-to,
.desc-leave-from {
  opacity: 1;
}

.desc-leave-to,
.desc-enter-from {
  opacity: 0;
}

.desc-leave-active,
.desc-enter-active {
  transition: opacity .3s ease-in-out;
}
</style>