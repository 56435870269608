<template>
  <div class="filler d-block d-lg-none"></div>
  <div class="phone-menu d-block d-lg-none">
    <div class="row">
      <div class="col-3">
        <router-link to="/"><fa-icon icon="home"></fa-icon> Home</router-link>
      </div>
      <div class="col-3">
        <router-link to="/investment"
          ><fa-icon icon="percentage"></fa-icon> Inwestycje</router-link
        >
      </div>
      <div class="col-3">
        <router-link to="/apartment"
          ><fa-icon icon="building"></fa-icon> Mieszkania</router-link
        >
      </div>
      <div class="col-3">
        <a style="cursor:pointer" @click="routeToContact"><fa-icon icon="envelope"></fa-icon> Kontakt</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    routeToContact() {
      document.getElementById('contact-us').scrollIntoView();
    }
  }
};
</script>

<style scoped>
.filler {
  width: 100%;
  height: 60px;
}
.phone-menu {
  position: fixed;
  width: 100vw;
  height: 60px;
  background: #c33b07;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.phone-menu a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.phone-menu svg {
  font-size: 3rem;
}

.col-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 60px;
  font-size: 1.6rem;
  color: white;
  border-right: #b33706;
}
</style>