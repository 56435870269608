<template>
  <div>
    <the-section class="mt-5" container v-if="investment">
      <div class="row">
        <div class="col-lg-6">
          <product-image :images="investment.images"></product-image>
        </div>
        <div class="col-lg-6">
          <the-card class="mt-0 mx-0" style="border-radius: 0">
            <template v-slot:title> {{ investment.name }} </template>
            Miasto:
            <span style="color: #c33b07">{{ investment.city.name }}</span
            ><br />
            Ulica:
            <span style="color: #c33b07"
              >{{ investment.location.address }}, {{ investment.number }}</span
            ><br />
            Typ nieruchomości:
            <span style="color: #c33b07; text-transform: capitalize">{{
              investment.type
            }}</span
            ><br />
            Dostępnych mieszkań:
            <span style="color: #c33b07">{{ apartments.length }}</span
            ><br />
            Typ oferty: <span style="color: #c33b07">Aktualne</span><br />
          </the-card>
          <the-map class="mt-4">
            <iframe
              :src="`https://maps.google.com/maps?q=${investment.longitude},${investment.latitude}&hl=pl&z=18&amp;output=embed`"
              width="600"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </the-map>
        </div>
      </div>
    </the-section>
    
    <!-- <div class="row mt-5" v-if="investment.id == 1 || investment.id == 2">
      <div class="col">
        <h1 style="
        max-width: 500px;
        margin: auto;
        border: 3px solid black;
        border-radius: 15px;
        padding: 8px;
        text-align: center;
        border-color: #c33b07;">Od 1 listopada podwyżka cen mieszkań</h1>
      </div>
    </div> -->

    <the-section title="Dostępne mieszkania" container v-if="appsCount">
      <the-checkbox
        @checkboxChecked="checkboxChange"
        :checked="onlyFree"
        value="onlyFree"
        >Pokazuj tylko aktualne</the-checkbox
      >
      <fa-icon class="icon" :class="{'active': showAs === 'table'}" icon="table" size="3x" @click="showAs = 'table'"></fa-icon>
      <fa-icon class="icon" :class="{'active': showAs === 'card'}" icon="chalkboard" size="3x" @click="showAs = 'card'"></fa-icon>
      <div class="row" v-if="apartments.length > 0">
        <div class="col">
          <card-container class="mt-3" v-if="showAs === 'card'" :five="investmentId == 5">
            <the-card v-for="apartment in apartments" :key="apartment.id" :five="investmentId == 5">
              <template v-if="apartment.images.length" v-slot:image>
                <img
                  :src="`${configBackendHttp}${configBackendUrl}/${apartment.images.find(x => x.main == true).path}`"
                />
                <div class="status" :class="getStatus(apartment.status)[0]">
                  {{ getStatus(apartment.status)[1] }}
                </div>
              </template>

              <template v-else v-slot:image>
                <img src="../assets/img/house-placeholder.gif" />
                <div class="status" :class="getStatus(apartment.status)[0]">
                  {{ getStatus(apartment.status)[1] }}
                </div>
              </template>
              <template v-slot:title> {{ apartment.description }} </template>

              <template v-slot:details>
                <span
                  ><b>{{ apartment.surface }} m²</b></span
                >
                <span>{{ rooms(apartment.rooms) }}</span>
                <span>{{ apartment.floor }}</span>
              </template>

              <template v-slot:number>
                {{ apartment.number }}
              </template>

              <template v-slot:price v-if="apartment.status !== 'sold'">
                <span class="price"
                  ><fa-icon style="color: darkgreen" icon="tag"></fa-icon>
                  {{ apartment.price }}zł
                  <small v-if="apartment.price_m2 && investmentId != 5">({{ apartment.price_m2 }}zł / m²)</small>
                  </span>
              </template>

              <template v-slot:buttons>
                <the-button v-if="investmentId == 5"
                  :route="`/apartment/${apartment.id}`"
                  small
                  tbcType="primary"
                  noAnim
                  >Oferta</the-button
                > <!-- <img class="c-icon" src="@/assets/img/icons8-chevron-right-48.png" alt="chevron-right" width="24"/> -->
                <the-button v-else
                  :route="`/apartment/${apartment.id}`"
                  small
                  tbcType="primary"
                  noAnim
                  >Oferta</the-button
                >
              </template>
            </the-card>
          </card-container>
          <div class="table-responsive" v-else>
            <table class="table mt-3">
              <thead>
                <tr>
                  <th scope="col">Nr</th>
                  <th scope="col">Powierzchnia</th>
                  <th scope="col">Taras</th>
                  <th scope="col">Ilość pokoi</th>
                  <th scope="col">Piętro</th>             
                  <th scope="col">Dostępność</th>
                  <th scope="col">Cena za m²</th>
                  <th scope="col">Cena</th>
                  <th scope="col">Szczegóły</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="apartment in apartments" :key="apartment.id">
                  <th scope="row">{{ apartment.number }}</th>
                  <td>{{ apartment.surface }}m²</td>
                  <td>{{ apartment.terrace && apartment.terrace_surface ? apartment.terrace_surface + 'm²' : '-' }}</td>
                  <td>{{ apartment.rooms }}</td>
                  <td>{{ apartment.floor }}</td>
                  <td :class="'color-'+apartment.status">{{ getStatus(apartment.status)[1] }}</td>
                  <td v-if="apartment.status !== 'sold'">{{ apartment.price_m2 ? apartment.price_m2 + ' zł/m²' : '-' }}</td><td v-else></td>
                  <td v-if="apartment.status !== 'sold'">{{ apartment.price }}zł</td><td v-else></td>
                  <td><the-button
                  :route="`/apartment/${apartment.id}`"
                  xSmall
                  tbcType="primary"
                  noAnim
                  >Oferta</the-button
                ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </the-section>
  </div>
</template>

<script>
import ProductImage from "../components/singleselling/ProductImage";
import CardContainer from "../components/CardContainer";
export default {
  props: ["investmentId"],
  data() {
    return {
      appsCount: 0,
      investment: null,
      onlyFree: false,
      showAs: 'table'
    };
  },
  watch: {
    investmentId() { 
      this.changedInvestmentId()
    }
  },
  computed: {
    apartments() {
      return this.onlyFree
        ? this.$store.getters["apartments/getResults"].filter(
            (x) => x.status === "free"
          )
        : this.$store.getters["apartments/getResults"];
    },
  },
  components: {
    ProductImage,
    CardContainer,
  },
  beforeMount() {
    this.changedInvestmentId();
  },
  methods: {
    async changedInvestmentId() {
      await this.$store.dispatch("selling/loadResults");
      this.investment = this.$store.getters["selling/getById"](this.investmentId);

      await this.$store.dispatch("apartments/loadResults", this.investmentId);
      this.appsCount = this.$store.getters["apartments/getResults"].length;
    },
    getStatus(status) {
      switch (status) {
        case "free":
          return ["success", "Aktualne"];
        case "reserved":
          return ["warning", "Zarezerwowane"];
        case "sold":
          return ["danger", "Sprzedane"];
      }
    },
    checkboxChange(value, checked) {
      this[value] = checked;
    },
    rooms(digit) {
      console.log(digit)
      return `${digit} ${digit < 2 ? 'Pokój' : digit < 5 ? 'Pokoje' : 'Pokoi'}`;
    }
  },
  inject: ["configBackendUrl", "configBackendHttp"],
};
</script>

<style scoped>
.table-responsive {
  display: block;
  overflow-x: auto;
  width: 100%;
}

table {
  width: 100%;
  max-width: 100%;
  font-size: 1.5em;
  text-align: center;
  border-collapse: collapse;
}

.table td, .table th {
  padding: .75rem;
  border-top: 1px solid #dee2e6;
  white-space:nowrap;
}

.table thead th {
  border-bottom: 2px solid #c33b07;
}

.icon {
  margin-top: 0.4em;
  margin-right: 0.4em;
  color: grey;
  cursor: pointer;
}

.icon.active {
  color: #c33b07;
}

.color-free {
  color: green;
}

.color-sold {
  color: red;
}

.color-reserved {
  color: orange;
}

.price {
  font-size: 1.5rem;
}
</style>