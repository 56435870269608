import { createApp } from 'vue'
import App from './App.vue'

import * as setup from './setup';

import Router from './router';

import FontAwesomeIcon from './fontawesome.js';

import TheButton from './components/ui/TheButton.vue';
import TheCard from './components/ui/TheCard.vue';
import TheSection from './components/ui/TheSection.vue';
import TheInput from './components/ui/TheInput.vue';
import TheLoader from './components/ui/TheLoader.vue';
import TheSelect from './components/ui/TheSelect.vue';
import TheBadge from './components/ui/TheBadge.vue';
import TheCheckbox from './components/ui/TheCheckbox.vue';
import TheMap from './components/ui/TheMap.vue';
import TheAlert from './components/ui/TheAlert.vue';
import SectionTitle from './components/ui/SectionTitle.vue';
import PhoneMenu from './components/ui/PhoneMenu.vue';

import store from './store'

const app = createApp(App).use(store);

app.use(Router);

app.component('fa-icon', FontAwesomeIcon);

app.component('the-button', TheButton);
app.component('the-card', TheCard);
app.component('the-section', TheSection);
app.component('the-loader', TheLoader);
app.component('the-input', TheInput);
app.component('the-select', TheSelect);
app.component('the-badge', TheBadge);
app.component('the-checkbox', TheCheckbox);
app.component('the-map', TheMap);
app.component('the-alert', TheAlert);
app.component('section-title', SectionTitle);
app.component('phone-menu', PhoneMenu);

app.provide('configBackendUrl', setup.backendUrl);
app.provide('configBackendHttp', setup.backendHttp);

app.mount('#app');