<template>
  <form @submit.prevent="sendForm" class="mt-5">
    <h3>Formularz kontaktowy</h3>
    <transition mode="out-in" name="formComplete">
      <div v-if="!formCompleted">
        <div class="row">
          <div class="col">
            <the-input
              label="Imię"
              :class="form.firstname.class"
              v-model="form.firstname.value"
              type="text"
              :errorMessage="form.firstname.error"
              
            ></the-input>
          </div>
          <div class="col">
            <the-input
              label="Nazwisko"
              :class="form.lastname.class"
              v-model="form.lastname.value"
              type="text"
              :errorMessage="form.lastname.error"
              
            ></the-input>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <the-input
              label="E-Mail"
              :class="form.email.class"
              v-model="form.email.value"
              type="email"
              :errorMessage="form.email.error"
              
            ></the-input>
          </div>
          <div class="col">
            <the-input
              label="Numer telefonu"
              :class="form.phone.class"
              v-model="form.phone.value"
              type="tel"
              :errorMessage="form.phone.error"
              
            ></the-input>
          </div>
        </div>
        <the-input
          class="mt-4"
          label="Wiadomość"
          isTextarea="true"
          :class="form.message.class"
          v-model="form.message.value"
          :errorMessage="form.message.error"
        ></the-input>
        <the-button
          class="mt-4 d-block"
          style="border-radius: 0"
          tbcType="primary"
          >Wyślij</the-button
        >
      </div>
      <div
        class="form_success mt-5 d-flex flex-column align-items-center justify-content-center"
        v-else
      >
        <fa-icon class="icon" icon="check-circle"></fa-icon>
        <p>
          Wiadomość została wysłana!<br />
          Skontaktujemy się najszybciej jak to możliwe.<br><br>Dziękujemy
        </p>
      </div>
    </transition>
  </form>
</template>

<script>
import * as setup from '../setup';

export default {
  data() {
    return {
      formCompleted: false,
      errors: 0,
      form: {
        firstname: {
          class: "",
          value: "",
          error: "",
        },
        lastname: {
          class: "",
          value: "",
          error: "",
        },
        email: {
          class: "",
          value: "",
          error: "",
        },
        phone: {
          class: "",
          value: "",
          error: "",
        },
        message: {
          class: "",
          value: "",
          error: "",
        },
      },
    };
  },
  methods: {
    cleanForm(all = true) {
      // Clean errors before Check
      for (const single in this.form) {
        if(all) this.form[single].value = "";
        this.form[single].class = "";
        this.form[single].error = "";
      }
      this.errors = 0;
    },
    async sendForm() {
      this.cleanForm(false);

      // Global validation of form values
      for (const single in this.form) {
        if (!this.form[single].value || this.form[single].value.length < 1) {
          this.form[single].class = "danger";
          this.form[single].error = "Pole nie może być puste";
          this.errors++;
        }
      }
      const mailCheck = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      if(!mailCheck.test(this.form.email.value)) {
        this.errors++;
        this.form.email.class = 'danger';
        this.form.email.error = 'Nieprawidłowy adres e-mail';
      }

      if(/[A-z][a-z]+$/.test(this.form.phone.value)) {
        this.errors++;
        this.form.phone.class = 'danger';
        this.form.phone.error = 'Nieprawidłowy numer telefonu';
      }

      // Check if there are any errors
      if(this.errors > 0) return;
      
      // Fire API Request
      try {
        const response = await fetch(`${setup.backendHttp}${setup.backendUrl}/api/contact`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body:JSON.stringify({
            firstname: this.form.firstname.value,
            lastname: this.form.lastname.value,
            email: this.form.email.value,
            phone: this.form.phone.value,
            message: this.form.message.value,
          })
        });
        if(response.ok) {
          // Everything worked, so show the state :)
          this.formCompleted = true;
          this.cleanForm();
          setTimeout(() => {
            this.formCompleted = false;
          }, 5000);
        }
      } catch(e) {
        this.form.message.class = 'danger';
        this.form.message.error = 'Wystąpił problem podczas wysyłania wiadomości. Spróbuj ponownie później';
      }
    },
  },
};
</script>

<style scoped>
h4 {
  font-weight: 400;
  font-size: 2.4rem;
}

form {
  background: #272525;
  padding: 2rem;
  border-top: 3px solid #c33b07;
  border-left: 3px solid #c33b07;
}

form h3 {
  font-weight: normal;
  border-left: 3px solid #c33b07;
  padding-left: 1rem;
  font-size: 2rem;
}

.form_success .icon {
  color: #3ed339;
  font-size: 8.4rem;
}

.form_success p {
  font-size: 1.4rem;
  margin-top: 1rem;
}

.formComplete-enter-from,
.formComplete-leave-to {
  opacity: 0;
}

.formComplete-enter-active,
.formComplete-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.formComplete-enter-to,
.formComplete-leave-from {
  opacity: 1;
}
</style>