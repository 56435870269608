import * as setup from '../../setup';

export default {
  namespaced: true,
  state() {
    return {
      foundResults: [],
      filteredResults: {
        apartments: [],
        currentPage: 1,
        totalItems: 0,
        totalPages: 0,
        price_min: 0,
        price_max: 1,
        surface_min: 0,
        surface_max: 1
      },
      filterPage: 1,
      filterLastQuery: []
    };
  },
  getters: {
    getResults(state) {
      return state.foundResults;
    },
    getById: (state) => (id) => {
      return state.foundResults.find((x) => x.id == id);
    },

    getFilteredResults(state) {
      return state.filteredResults;
    },

    getFilterLastQuery(state) {
      return state.filterLastQuery;
    }
  },
  mutations: {
    setFoundResults(state, payload) {
      state.foundResults = payload;
    },
    setFilteredResults(state, payload) {
      state.filteredResults = payload;
    },
    setCurrentPage(state, payload) {
      state.filterPage = payload;
    },
    setFilterLastQuery(state, payload) {
      state.filterLastQuery = payload;
    }
  },
  actions: {
    async loadResults(context, id) {
      context.commit(
        "setIsLoading",
        { triggeredBy: "apartments" },
        { root: true }
      );
      try {
        const response = await fetch(
          `${setup.backendHttp}${setup.backendUrl}/api/investments/${id}/apartments`
        );
        const data = await response.json();
        context.commit("setFoundResults", data.apartments);
      } catch (e) {
        context.commit("setException", { message: e.message }, { root: true });
      } finally {
        context.commit(
          "setIsLoading",
          { triggeredBy: "apartments" },
          { root: true }
        );
      }
    },
    async loadSingleResult(context, id) {
      context.commit(
        "setIsLoading",
        { triggeredBy: "apartments" },
        { root: true }
      );
      try {
        const response = await fetch(
          `${setup.backendHttp}${setup.backendUrl}/api/apartments/${id}`
        );
        const data = await response.json();
        context.commit("setFoundResults", data.apartment);
      } catch (e) {
        context.commit("setException", { message: e.message }, { root: true });
      } finally {
        context.commit(
          "setIsLoading",
          { triggeredBy: "apartments" },
          { root: true }
        );
      }
    },
    async loadFilteredResults(context, query) {
      try {
        if(!query) query='';
        context.commit('setIsLoading',{triggeredBy: 'apartments'},{root:true});
        const response = await fetch(`${setup.backendHttp}${setup.backendUrl}/api/apartments${query}`);
        const data = await response.json();
        if(!response.ok) throw new Error('There\'s an error');
        return context.commit('setFilteredResults', data.response);
      } catch(e) {
        context.commit('setException',{message: e.message},{root:true});
      } finally {
        context.commit('setIsLoading',{triggeredBy: 'apartments'},{root:true});
      }
    }
  },
};
