<template>
  <button :class="classSetter" v-if="!url && !route">
    <slot></slot>
  </button>
  <a v-else-if="url && !route" :class="classSetter" :href="url">
    <slot></slot>
  </a>
  <router-link v-else :class="classSetter" :to="route"><slot></slot></router-link>
</template>

<script>
export default {
  props: ['tbcType','url','big','route','small','xSmall','no-anim'],
  computed: {
    classSetter() {
      return {
        'secondary' : this.tbcType == 'secondary',
        'primary' : this.tbcType == 'primary' && this.noAnim === undefined,
        'big' : this.big !== undefined,
        'small' : this.small !== undefined,
        'x-small' : this.xSmall !== undefined,
        'primary-noAnim' : this.noAnim !== undefined
      }
    }
  }
}
</script>

<style scoped>
  button, a {
    background:none;
    color:white;
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
    border:none;
    padding:1.5rem 5rem;
    border-radius: 50px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
  }

  a{
    display: inline-block;
    clear: left;
  }

  .big:is(button,a) {
    padding:2rem 7rem;
    font-size: 2.1rem;
  }

  .primary:is(button,a) {
    background: #C33B07;
    transition: padding-right .3s cubic-bezier(.68,-0.55,.27,1.55);
  }

  .primary:is(button,a).success {
    position: relative;
    background: transparent;
    z-index: 1;
  }

  .primary:is(button,a).success::before {
    content: '';
    position:absolute;
    top:0;
    left:0;
    z-index: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    box-sizing: border-box;
    border:3px solid #146e11;
    animation: grow .3s cubic-bezier(1,0,0,1) forwards;
  }

  .primary-noAnim:is(button,a) {
    background: #C33B07;
    transition: background .3s ease-in-out;
  }

  .primary-noAnim:is(button,a):hover {
    background: #9b3006;
  }

  .primary:is(button,a):hover {
    padding-right: 8rem;
  }

  .big:is(button,a):hover {
    padding-right: 12rem;
  }

  .small:is(button,a) {
    padding: 1rem 1.2rem;
    display: flex;
    font-size: 1.3rem;
  }

  .x-small:is(button,a) {
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
  }

  .secondary:is(button,a) {
    box-shadow: inset 0 0 0 5px #C33B07;
  }

  .secondary:is(button,a):hover {
    animation: pulse 1s infinite;
    box-shadow: 0 0 0 1em #C33B07;
  }


</style>