<template>
  <span id="writer" :class="{'animateMe' : onScreen}"><slot></slot></span>
</template>

<script>
export default {
  props: ['steps'],
  data() {
    return {
      onScreen: false
    }
  },
  methods: {
    appendClass(entries) {
      entries.forEach(entry => {
        if(entry.isIntersecting) this.onScreen = true;
        else this.onScreen = false;
      });
    }
  },
  mounted() {
    const writer = document.getElementById('writer');
    const options = {
      root:null,
      rootMargin:'0px',
      threshold: .3
    };
    const observer = new IntersectionObserver(this.appendClass, options);
    observer.observe(writer);

    if(!this.steps || this.steps == 40) return;
    writer.style.animationTimingFunction = `steps(${this.steps}, end)`;
  },
}
</script>

<style scoped>
span {
  overflow: hidden;
  width: 0;
  white-space: nowrap;
  display: inline-block;
  letter-spacing: .27rem;
}

span.animateMe {
  animation: typing 3.5s ease-in-out forwards;
}
</style>