<template>
  <span id="counter">{{ calculatedNumber }}</span>
</template>

<script>
export default {
  props: ["number", "duration"],
  data() {
    return {
      calculatedNumber: 0,
      animDuration: +this.duration || 2000,
      startTimestamp: null
    }
  },
  methods: {
    stepAnimation(timestamp) {
      if(!this.startTimestamp) this.startTimestamp = timestamp;
      const progress = Math.min((timestamp - this.startTimestamp) / this.animDuration, 1);
      this.calculatedNumber = Math.floor(progress * (this.number - 0) + 0);
      if(progress < 1) window.requestAnimationFrame(this.stepAnimation);
    },
    elementVisible(entries) {
      entries.forEach(entry => {
        if(entry.isIntersecting) window.requestAnimationFrame(this.stepAnimation);
      });
    }
  },
  mounted() {
    const options = {
      root:null,
      rootMargin:'0px',
      threshold: .3
    };
    const observer = new IntersectionObserver(this.elementVisible, options);
    observer.observe(document.getElementById('counter'));
  },
};
</script>

<style scoped>
span {
  font-size: 2em;
}
</style>