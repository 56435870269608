<template>
  <div class="form__group field" :class="$attrs.class">
    <textarea
      class="form__field"
      v-if="isTextarea"
      :placeholder="label"
      :name="normalizedLabel"
      :id="normalizedLabel"
      @input="handleInput"
      :value="modelValue"
      cols="30"
      rows="3"
      style="resize: vertical"
    >
    </textarea>
    <input
      v-else
      :type="type"
      class="form__field"
      :placeholder="label"
      :name="normalizedLabel"
      :id="normalizedLabel"
      @input="handleInput"
      :value="modelValue"
    />
    <label :for="normalizedLabel" class="form__label">{{
      label
    }}</label>
  </div>
  <small v-if="errorMessage">{{ errorMessage }}</small>
</template>

<script>
export default {
  props: ["type", "label", "isTextarea", "modelValue", "errorMessage"],
  data() {
    return {
      content: this.modelValue,
    };
  },
  computed: {
    theType() {
      return !this.type ? "text" : this.type;
    },
    normalizedLabel() {
      return this.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
  },
  methods: {
    handleInput(e) {
      this.content = e.target.value;
      this.$emit("update:modelValue", this.content);
    },
  },
};
</script>

<style scoped>
/** #c33b07 */

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #ccc;
  outline: 0;
  font-size: 1.6rem;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 1.6rem;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1.3rem;
  color: #ccc;
}

.form__group.danger .form__label {
  color: #d33939;
}

.form__group.danger .form__field {
  border-color: #d33939;
}

.form__group.success .form__label {
  color: #3ed339;
}

.form__group.success .form__field {
  border-color: #3ed339;
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1.3rem;
  color: #c0603b;
  font-weight: 700;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #c33b07, #c0603b);
  border-image-slice: 1;
}

/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

small {
  font-size: 1.3rem;
  font-weight: 300;
}
</style>