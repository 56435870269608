<template>
  <card-container class="cContainer grow">
    <the-card flexColumn="true" forcewidth="test" centerText="true">
      <!-- <fa-icon icon="clock"></fa-icon> -->
      <img class="removeBorder fixWidth" src="../../assets/img/about-us-time.jpg" alt="13 lat na rynku" />
      <span>
        <Counter number="13" /> lat na rynku<br />
        od ponad dekady działamy dla klientów!
      </span>
    </the-card>

    <the-card flexColumn="true" forcewidth="test" centerText="true">
      <!-- <fa-icon icon="warehouse"></fa-icon> -->
      <img class="removeBorder fixWidth" src="../../assets/img/about-us-investments.jpg" alt="Ponad 20 inwestcji" />
      <span>
        Ponad <Counter number="20" /> inwestycji lokalnych<br />
        Stawiamy na rozwój naszych regionów!
      </span>
    </the-card>

    <the-card flexColumn="true" forcewidth="test" centerText="true">
      <!-- <fa-icon icon="building"></fa-icon> -->
      <img class="removeBorder fixWidth" src="../../assets/img/about-us-apartments.jpg" alt="Ponad 100 mieszkan" />
      <span>
        ponad <Counter number="100" /> mieszkań oddanych do <br />użytku
        W wysokim standardzie!
      </span>
    </the-card>
  </card-container>
</template>

<script>
import Counter from "../Counter";
import CardContainer from "../CardContainer";


export default {
  components: {
    Counter,
    CardContainer
  },
};
</script>

<style scoped>
.cContainer {
  font-size: 2rem;
  text-transform: uppercase;
}

.cContainer svg {
  font-size: 10rem;
  margin-bottom: 3rem;
  color: #c33b07;
}

.removeBorder {
  border: 0px !important;
}

.fixWidth {
  width: auto !important;
}
</style>