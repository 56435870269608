<template>
  <the-loader :show="showLoader"></the-loader>
  <the-header :buttons="displayHeaderButtons"></the-header>
  <router-view v-slot="slotProps">
    <transition name="route" mode="out-in">
      <keep-alive>
        <component :is="slotProps.Component" ></component>
      </keep-alive>
    </transition>
  </router-view>
  <the-section container class="py-5" grey v-if="this.$route.name !== 'newsletterSubscribed'">
    <newsletter></newsletter>
  </the-section>
  <the-section id="partnership" class="mt-0 pb-5">
    <ThePartnerShips/>
  </the-section>
  <the-section id="contact-us" dark class="mt-0 pb-5">
    <contact-us></contact-us>
  </the-section>

  <the-footer></the-footer>

  <scroll-to :show="showScrollTo"></scroll-to>
  <!-- Bottomside phone menu -->
  <phone-menu></phone-menu>
</template>

<script>
import TheHeader from "./components/layout/TheHeader.vue";
import TheFooter from "./components/layout/TheFooter.vue";
import ScrollTo from "./components/ui/ScrollTo.vue";
import ContactUs from "./components/homepage/ContactUs.vue";
import Newsletter from './components/homepage/Newsletter.vue';
import ThePartnerShips from "./components/layout/ThePartnerShips.vue";

export default {
  name: "App",
  components: {
    TheHeader,
    ContactUs,
    ScrollTo,
    TheFooter,
    Newsletter,
    ThePartnerShips
},
  data() {
    return {
      showScrollTo: false,
    };
  },
  computed: {
    displayHeaderButtons() {
      return this.$route.name == "home";
    },
    showLoader() {
      return this.$store.getters.getIsLoading;
    },
  },
  methods: {
    scrollFunction() {
      if (
        document.body.scrollTop > 200 ||
        document.documentElement.scrollTop > 200
      ) {
        this.showScrollTo = true;
        return;
      }
      this.showScrollTo = false;
    },
  },
  watch: {
    $route(val, old) {
      if ((val.name !== old.name) || (JSON.stringify(val.params) != JSON.stringify(old.params))) {
        setTimeout(() => {
          window.scroll(0, 0);
        }, 400);
      }
    },
  },
  mounted() {
    // Show scrollTo button
    window.addEventListener("scroll", this.scrollFunction);
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 56.25%;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  overflow: hidden;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
  html {
    font-size: 61%;
  }
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
}

.route-enter-active,
.route-leave-active {
  transition: opacity 0.5s ease;
}


.route-enter-from,
.route-leave-to {
  opacity: 0;
}

/* .route-enter-from {
  opacity: 0;
  transform: scaleY(0);
}

.route-leave-to {
  opacity: 0;
  transform: scaleY(0);
}

.route-enter-active {
  transition: all 0.3s ease-out;
  transform-origin: bottom;
}

.route-leave-active {
  transition: all 0.3s ease-in;
  transform-origin: bottom;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: scaleY(1);
} */

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 5px #c33b07;
  }
  50% {
    box-shadow: 0 0 0 1em #c33b07;
  }
  100% {
    box-shadow: 0 0 0 5px #c33b07;
  }
}
@keyframes grow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
</style>
