<template>
  <div class="alert" :class="classSetter">
    <fa-icon icon="exclamation-triangle"></fa-icon>
    <span><slot></slot></span>
  </div>
</template>

<script>
export default {
  props:['type'],
  computed: {
    classSetter() {
      return {
        'warning' : this.type == 'warning'
      }
    }
  }
}
</script>

<style scoped>
  .alert {
    font-size: 1.3rem;
    background: #aaa;
    padding:1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    color:white;
  }

  .alert :deep() svg {
    font-size: 5rem;
    margin-right: 1rem;
  }

  .alert.warning {
    background: #e9a411;
    border: 1px solid #d69b1b;
    color:black;
  }

  .alert.warning :deep() svg{
    color: #0000004d;
  }
</style>