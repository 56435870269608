<template>
  <section :class="{'dark' : dark !== undefined, 'grey' : grey !== undefined}">
    <section-title v-if="title">{{ title }}</section-title>
    <div :class="{'container': container !== undefined}">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  props: ['dark','grey','title','container']
}
</script>

<style scoped>
section {
  margin-top: 4rem;
}

section:first-child {
  margin-top:0;
}

section.dark {
  background: #303030;
  color: white;
}

section.grey {
  background: #575757;
}

/* @media (min-width: 1400px) {
  .container-extra {
    max-width: 1450px;
  }
} */

</style>