<template>
  <single-step style="cursor: pointer" step="1" result="Test">
    <span @click="showSelect($event)"
      >Wybierz interesujące Cię miasto <fa-icon icon="angle-down"></fa-icon
    ></span>
    <template v-slot:result>
      <span v-if="selectedCity" class="ms-5 result" style="color: #c33b07">{{
        selectedCity.name
      }}</span>
    </template>
  </single-step>
  <the-select
    @selected="citySelected"
    @hideSelect="hideSelect"
    v-if="select.show"
    :x="select.x"
    :y="select.y"
  ></the-select>

  <single-step v-if="step >= 2" step="2" result="Test">
    Wybierz interesujące Cię typy inwestycji
    <template v-slot:result>
      <the-checkbox
        @checkboxChecked="checkboxChange"
        :checked="checkboxChecked.blok"
        value="blok"
        class="ms-3"
        >Blok</the-checkbox
      >
      <the-checkbox
        @checkboxChecked="checkboxChange"
        :checked="checkboxChecked.segment"
        value="segment"
        class="ms-3"
        >Segment</the-checkbox
      >
    </template>
  </single-step>
</template>

<script>
import SingleStep from "./SingleStep.vue";
export default {
  components: {
    SingleStep,
  },
  data() {
    return {
      select: {
        show: false,
        x: 0,
        y: 0,
      },
      checkboxChecked: {
        blok: false,
        segment: false,
      },
      step: 1,
    };
  },
  computed: {
    selectedCity() {
      const filters = this.$store.getters["selling/getFilters"];
      return filters ? filters.city : null;
    },
    checkedFilters() {
      const filters = this.$store.getters["selling/getFilters"];
      return filters ? filters.investmentTypes : null;
    },
  },
  methods: {
    showSelect(event) {
      this.select.x = event.clientX + window.scrollX;
      this.select.y = event.clientY + window.scrollY;
      this.select.show = true;
    },
    hideSelect() {
      this.select.show = false;
    },
    citySelected(city) {
      if (JSON.stringify(this.selectedCity) == JSON.stringify(city)) {
        this.$store.commit("selling/setFilters", { city: null });
        this.step = 1;
      }
      this.$store.commit("selling/setFilters", { city: city });
      this.step = 2;
    },
    checkboxChange(value, checked) {
      this.checkboxChecked[value] = !this.checkboxChecked[value];
      const foundFilter = this.checkedFilters.indexOf(value);
      if (foundFilter > -1) {
        if (checked) return;
        this.checkedFilters.splice(foundFilter, 1);
      } else {
        this.checkedFilters.push(value);
      }
      this.$store.commit("selling/setFilters", {
        investmentTypes: this.checkedFilters,
      });
    },
  },
  mounted() {
    if (this.selectedCity) this.step = 2;
    if (this.checkedFilters.length) {
      this.step = 3;
      for(let checkbox in this.checkboxChecked) {
        this.checkboxChecked[checkbox] = this.checkedFilters.includes(checkbox);
      }
    }
  },
};
</script>

<style scoped>
span {
  margin-left: 0;
  font-size: 1.6rem;
}
</style>