import * as setup from '../../setup'

export default {
  namespaced: true,
  state() {
    return {
      foundResults: [],
      sellingPageFilter: {
        city: null,
        investmentTypes: []
      }
    };
  },
  getters: {
    getResults(state) {
      return state.foundResults;
    },
    getById: (state) => (id) => {
      return state.foundResults.find(x => x.id == id);
    },
    getCurrentOffer(state) {
      if(state.foundResults.length <= 4) return state.foundResults;
      return state.foundResults.slice(state.foundResults.length-5, state.foundResults.length-1)
    },
    getFilteredResults(state) {
      if(!state.sellingPageFilter.city) return;
      const filteredByCity = state.foundResults.filter(x => x.city.id == state.sellingPageFilter.city.id);
      if(state.sellingPageFilter.investmentTypes.length < 1) return [];
      return filteredByCity.filter(x => state.sellingPageFilter.investmentTypes.includes(x.type.toLowerCase()));
    },
    getFilters(state) {
      return state.sellingPageFilter;
    }
  },
  mutations: {
    setFoundResults(state, payload) {
      state.foundResults = payload;
    },
    setFilters(state, {city, types}) {
      if(city) state.sellingPageFilter.city = city;
      if(types) state.sellingPageFilter.investmentTypes = types;
    }
  },
  actions: {
    async loadResults(context) {
      context.commit('setIsLoading',{triggeredBy: 'selling'},{root:true});
      try {
        if(context.state.foundResults.length>0) {
          return;
        }
        const response = await fetch(`${setup.backendHttp}${setup.backendUrl}/api/investments`);
        const data = await response.json();
        context.commit('setFoundResults', data.investments);
        window.test = data.investments
      } catch(e) {
        context.commit('setException',{message: e.message},{root:true});
      } finally {
        context.commit('setIsLoading',{triggeredBy: 'selling'},{root:true});
      }
    }
  }
};
