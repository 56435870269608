<template>
  <div>
    <the-section container title="Aktualna oferta">
      <card-container>
        <the-card v-for="x in currentOffer" :key="x.id">
          <template v-slot:image>
            <router-link :to="`/investment/${x.id}`">
              <img :src="`${configBackendHttp}${configBackendUrl}/${x.images[0].path}`" />
            </router-link>
          </template>
          <template v-slot:title> {{x.name}} </template>

          Miasto: {{x.city.name}}<br />
          Ulica: {{x.location.address}}, {{ x.number }}<br />
          Typ: Aktualne

          <template v-slot:buttons>
            <the-button :route="`/investment/${x.id}`" small tbcType="primary">Przejdź do oferty</the-button>
          </template>
        </the-card>
      </card-container>
      <div class="d-flex justify-content-center mt-5">
        <the-button route="/investment" tbcType="primary" big
          >Sprawdź całą ofertę</the-button
        >
      </div>
    </the-section>
    <the-section container title="dlaczego my?">
      <about-us></about-us>
    </the-section>
    <how-we-build></how-we-build>
  </div>
</template>

<script>
import CardContainer from "../components/CardContainer";
import AboutUs from "../components/homepage/AboutUs.vue";
import HowWeBuild from '../components/homepage/HowWeBuild.vue';

export default {
  components: {
    CardContainer, // Lista kart na stronie głównej
    AboutUs,// Tekst "O nas" na stronie głównej
    HowWeBuild
  },
  computed: {
    currentOffer() {
      return this.$store.getters['selling/getCurrentOffer']
    }
  },
  mounted() {
    this.$store.dispatch('cities/loadResults');
    this.$store.dispatch('selling/loadResults');
  },
  inject:['configBackendUrl','configBackendHttp']
};
</script>

<style scoped>
</style>