<template>
  <transition name="fade">
    <div v-if="show" @click="scrollToTop">
      <fa-icon class="fa-3x" icon="angle-up"></fa-icon>
    </div>
  </transition>
</template>

<script>
export default {
  props: ['show'],
  methods: {
    scrollToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  },
};
</script>

<style scoped>
div {
  background: #c33b07;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 80px;
  transform: translateX(50%);
  right: 50%;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.14);
  border-radius: 50%;
  cursor: pointer;
}

@media (min-width: 992px) {
  div {
    bottom: 20px;
    right:20px;
    transform: translateX(0);
  }
}

div svg {
  color: white;
}

.fade-enter-active,
.fade-leave-active {
  transition: right .5s ease;
}

.fade-enter-from,
.fade-leave-to {
  right: -100px;
}

</style>