<template>
  <span>
    <slot></slot>
  </span>
</template>

<script>
export default {

}
</script>

<style scoped>
span {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:0 1rem;
  padding:.5rem 1rem;
  border-radius: 8px;
  background: #c4c4c4;
  color: black;
  font-size: 1.5rem;
}
</style>