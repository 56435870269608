<template>
  <ul>
    <li><router-link to="/">Home</router-link></li>
    <li><router-link to="/investment">Inwestycje</router-link></li>
    <li><router-link to="/apartment">Mieszkania</router-link></li>
    <li><a style="cursor:pointer" @click="routeToContact">Kontakt</a></li>
  </ul>
</template>

<script>
export default {
  methods: {
    routeToContact() {
      document.getElementById('contact-us').scrollIntoView();
    }
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

ul li {
  margin:0 2rem;
  font-size: 1.7rem;
  font-weight: 400;
  text-transform: uppercase;
}

ul li a {
  text-decoration: none;
  color:#a4a4a4;
  transition: color .3s ease-in-out;
}

ul li a:hover {
  color:#c33b07;
}

ul li a.router-link-active {
  color:#fff;
}

ul li:last-child {
  margin-right: 0;
}
</style>