<template>
  <div>
    <the-section class="mt-5" container v-if="apartment">
      <div class="row">
        <div class="col-lg-6">
          <product-image :images="apartment.images"></product-image>
        </div>
        <div class="col-lg-6">
          <the-card class="mt-0 mx-0">
            <template v-slot:title> {{ apartment.description }} </template>
            <p>
              Numer: <span style="color: #c33b07">{{ apartment.number }}</span
              ><br />
              Piętro: <span style="color: #c33b07">{{ apartment.floor }}</span
              ><br />
              Metraż:
              <span style="color: #c33b07">{{ apartment.surface }}m²</span
              ><br />
              Ogrzewanie:
              <span style="color: #c33b07">{{ apartment.heating }}</span
              ><br />
              Taras:
              <span style="color: #c33b07">{{
                apartment.terrace ? "Tak" : "Nie"
              }}</span
              ><br />
              <span v-if="apartment.terrace">
                Metraż tarasu:
                <span v-if="apartment.terrace_surface" style="color: #c33b07"
                  >{{ apartment.terrace_surface }}m²</span
                >
                <span v-else style="color: #c33b07"
                  >Nieznany</span
                >
                <br />
              </span>
              Okna: <span style="color: #c33b07">{{ apartment.windows }}</span>
              <br><br>
              <span class="main_price" v-if="apartment.status !== 'sold'"><fa-icon icon="tag"></fa-icon>{{ apartment.price }}zł</span>
              <span class="main_price_qm" v-if="apartment.price_m2 && apartment.status !== 'sold'">({{ apartment.price_m2 }}zł/m²)</span>
            </p>
          </the-card>
          <the-card v-if="investment">
            <template v-slot:image>
              <img
                :src="`${configBackendHttp}${configBackendUrl}/${investment.images[0].path}`"
              />
            </template>
            <template v-slot:title>
              Inwestycja - {{ investment.name }}
            </template>

            Miasto: {{ investment.city.name }}<br />
            Ulica: {{ investment.location.address }}, {{ investment.number
            }}<br />

            <template v-slot:buttons>
              <the-button
                :route="`/investment/${investment.id}`"
                small
                tbcType="primary"
                >Przejdź do inwestycji</the-button
              >
            </template>
          </the-card>
        </div>
      </div>
    </the-section>

    <!-- <div class="row mt-5" v-if="investment.id == 1 || investment.id == 2">
      <div class="col">
        <h1 style="
        max-width: 500px;
        margin: auto;
        border: 3px solid black;
        border-radius: 15px;
        padding: 8px;
        text-align: center;
        border-color: #c33b07;">Od 1 listopada podwyżka cen mieszkań</h1>
      </div>
    </div> -->

    <the-section
      title="Inne w tej inwestycji"
      container
      v-if="appsCount"
    >
      <the-checkbox
        @checkboxChecked="checkboxChange"
        :checked="onlyFree"
        value="onlyFree"
        >Pokazuj tylko aktualne</the-checkbox
      >
      <card-container class="mt-3" :five="investment.id == 5">
        <the-card v-for="apartment in apartments" :key="apartment.id" :five="investment.id == 5">
          <template v-if="apartment.images.length" v-slot:image>
            <img
              :src="`${configBackendHttp}${configBackendUrl}/${apartment.images.find(x => x.main == true).path}`"
            />
            <div class="status" :class="getStatus(apartment.status)[0]">
              {{ getStatus(apartment.status)[1] }}
            </div>
          </template>

          <template v-else v-slot:image>
            <img src="../assets/img/house-placeholder.gif" />
            <div class="status" :class="getStatus(apartment.status)[0]">
              {{ getStatus(apartment.status)[1] }}
            </div>
          </template>
          <template v-slot:title> {{ apartment.description }} </template>

          <template v-slot:details>
            <span
              ><b>{{ apartment.surface }} m²</b></span
            >
            <span>{{ rooms(apartment.rooms) }}</span>
            <span>{{ apartment.floor }}</span>
          </template>

          <template v-slot:number>
            {{ apartment.number }}
          </template>

          <template v-slot:price>
            <span class="price" style="font-size:1.5rem" v-if="apartment.status !== 'sold'"
              ><fa-icon style="color: darkgreen" icon="tag"></fa-icon>
              {{ apartment.price }}zł
              <small v-if="apartment.price_m2 && investment.id != 5">({{ apartment.price_m2 }}zł / m²)</small></span
            >
            <span v-else></span>
          </template>

          <template v-slot:buttons>
            <the-button
              :route="`/apartment/${apartment.id}`"
              small
              tbcType="primary"
              noAnim
              >Oferta</the-button
            >
          </template>
        </the-card>
      </card-container>
    </the-section>
  </div>
</template>

<script>
import ProductImage from "../components/singleselling/ProductImage";
import CardContainer from "../components/CardContainer";

export default {
  props: ["apartmentId"],
  data() {
    return {
      apartment: null,
      investment: null,
      appsCount: 0,
      onlyFree: false
    };
  },
  watch: {
    apartmentId() { 
      this.changedApartmentId()
    }
  },
  computed: {
    apartments() {
      const apartments = this.$store.getters["apartments/getResults"];
      return this.onlyFree ? apartments.filter((x) => (x.status === 'free')) : apartments;
      //return this.onlyFree ? apartments.filter((x) => (x.id != this.apartmentId) && (x.status === 'free')) : apartments.filter((x) => (x.id != this.apartmentId));
    }
  },
  components: {
    ProductImage,
    CardContainer,
  },
  beforeMount() {
    this.changedApartmentId();
  },
  methods: {
    async changedApartmentId() {
      // Load single apartment (fetch if empty)
    this.apartment = this.$store.getters["apartments/getById"](
      this.apartmentId
    );
    if (!this.apartment) {
      await this.$store.dispatch(
        "apartments/loadSingleResult",
        this.apartmentId
      );
      this.apartment = this.$store.getters["apartments/getResults"];
    }

    // Load all apartments for the investment of current apartment
    await this.$store.dispatch(
      "apartments/loadResults",
      this.apartment.investmentId
    );
    this.appsCount = this.$store.getters["apartments/getResults"].length;
    
    // Load investment data of current apartment
    this.investment = this.$store.getters["selling/getById"](
      this.apartment.investmentId
    );
    if (!this.investment) {
      await this.$store.dispatch("selling/loadResults");
      this.investment = this.$store.getters["selling/getById"](
        this.apartment.investmentId
      );
    }
    // await this.$store.dispatch("apartments/loadResults", this.apartmentId);
    },
    getStatus(status) {
      switch (status) {
        case "free":
          return ["success", "Aktualne"];
        case "reserved":
          return ["warning", "Zarezerwowane"];
        case "sold":
          return ["danger", "Sprzedane"];
      }
    },
    checkboxChange(value, checked) {
      this[value] = checked;
    },
    rooms(digit) {
      console.log(digit)
      return `${digit} ${digit < 2 ? 'Pokój' : digit < 5 ? 'Pokoje' : 'Pokoi'}`;
    }
  },
  inject: ["configBackendUrl", "configBackendHttp"],
};
</script>

<style scoped>
.main_price {
  font-size: 1.6rem;
}

.main_price_qm {
  margin-left: .5rem;
  font-size: 1.4rem;
}

.main_price :deep() svg {
  color:darkgreen;
  margin-right: .5rem;
}
</style>