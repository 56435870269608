<template>
  <header :class="{ animate: animation }">
    <div class="container px-3 px-md-0">
      <div
        class="d-flex align-items-center justify-content-center justify-content-lg-between"
      >
        <router-link to="/"><img src="../../assets/img/logo.png" alt="TBC Development" /></router-link>
        <the-navigation class="d-none d-lg-flex"></the-navigation>
      </div>
      <h1>
        Spełnij <span style="color: #c33b07">marzenie</span>, znajdź swój nowy
        <span style="color: #c33b07">dom</span>
      </h1>
      <h2 class="mt-2">
        Profesjonalna realizacja i projekty budynków mieszkalnych
      </h2>
      <div class="d-flex flex-column align-items-center flex-md-row">
        <the-button
          v-if="buttons"
          route="/investment"
          tbcType="secondary"
          class="mt-5"
          >Sprawdź ofertę</the-button
        >
        <the-button
          v-if="buttons"
          @click="routeToContact"
          tbcType="primary"
          class="mt-5 ms-md-5"
          >Masz pytania?</the-button
        >
      </div>
    </div>
  </header>
</template>

<script>
import TheNavigation from "./TheNavigation";

export default {
  props: {
    buttons: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      animation: true,
    };
  },
  components: {
    TheNavigation,
  },
  methods: {
    animate() {
      this.animation = true;
      setTimeout(() => {
        this.animation = false;
      }, 300);
    },
    routeToContact() {
      document.getElementById('contact-us').scrollIntoView();
    }
  },
  mounted() {
    this.animate();
  },
  watch: {
    buttons() {
      this.animate();
    },
  },
};
</script>

<style scoped>
header {
  width: 100%;
  height: auto;
  padding-bottom: 4rem;
  background: url("../../assets/img/header.png");
  background-size: cover;
  background-position: center;
  color: white;
  transform: scaleY(1);
  opacity: 1;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform-origin: top;
  overflow: hidden;
}

header.animate {
  opacity: 0;
  transform: scaleY(0);
}

.container h1 {
  margin-top: 2rem;
  font-size: 3.3rem;
}

.container h2 {
  font-size: 2.5rem;
  font-weight: 400;
}

@media (min-width: 992px) {
  .container h1 {
    margin-top: 2rem;
    font-size: 4.6rem;
  }
}

</style>