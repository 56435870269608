<template>
  <div @click="check">
    <div v-if="!propChecked" class="checkbox"></div>
    <div v-else class="checkbox checked"><img src="../../assets/img/check.svg" alt=""></div>
    <span><slot></slot></span>
  </div>
</template>

<script>
export default {
  props: ['value','checked'],
  emits: ['checkboxChecked'],
  data() {
    return {
      propChecked: !!this.checked
    }
  },
  methods: {
    check() {
      this.propChecked = !this.propChecked;
      this.$emit('checkboxChecked', this.value, this.propChecked);
    }
  },
}
</script>

<style scoped>
div {
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}

div span {
  margin-left: .5rem;
  font-size: 1.6rem;
}

.checkbox {
  width: 18px;
  height: 18px;
  border: 2px solid #c33b07;
}

.checkbox.checked {
  border-color: #173F04;
}

.checkbox.checked img {
  width:90%;
  height: auto;
}
</style>