import { createStore } from 'vuex'

import sellingModule from './modules/selling';
import citiesModule from './modules/cities';
import apartmentsModule from './modules/apartments';

export default createStore({
  namespaced:true,
  state() {
    return { 
      isLoading: [],
      exception: 0,
    };
  },
  getters: {
    getIsLoading(state) {
      return !!(state.isLoading.length > 0 || state.exception);
    },
    getException(state) {
      return state.exception;
    }
  },
  mutations: {
    setIsLoading(state, {triggeredBy}) {
      const foundIndex = state.isLoading.findIndex(x => x.toLowerCase() == triggeredBy.toLowerCase());
      if(foundIndex != -1) {
        state.isLoading.splice(foundIndex, 1);
      } else {
        state.isLoading.push(triggeredBy);
      }
    },
    setException(state) {
      state.exception++;
      setTimeout(() => {
        state.exception--;
      }, 5000);
    }
  },
  actions: {
    
  },
  modules: { 
    selling: sellingModule,
    cities: citiesModule,
    apartments: apartmentsModule
  },
});
