import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleUp, faHome, faAngleDown, faAngleLeft, faAngleRight, faExclamationTriangle, faCheckCircle, faTag, faClock, faBuilding, faWarehouse, faSearch, faPercentage, faTimes, faEnvelope, faTable, faChalkboard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faAngleUp);
library.add(faAngleDown);
library.add(faAngleRight);
library.add(faAngleLeft);
library.add(faClock);
library.add(faBuilding);
library.add(faWarehouse);
library.add(faEnvelope);
library.add(faPercentage);
library.add(faEnvelope);
library.add(faSearch);
library.add(faHome);
library.add(faTimes);
library.add(faTag);
library.add(faCheckCircle);
library.add(faExclamationTriangle);
library.add(faChalkboard);
library.add(faTable);
export default FontAwesomeIcon;