<template>
  <the-alert v-if="!results.apartments.length" type="warning">
    Brak wyników dla wybranych filtrów<br>Spróbuj usunąć niektóre z nich.
  </the-alert>
  <card-container>
    <the-card v-for="apartment in results.apartments" :key="apartment.id">
      <template v-if="apartment.images.length" v-slot:image>
        <img
          :src="`${configBackendHttp}${configBackendUrl}/${apartment.images.find(x => x.main == true).path}`"
        />
        <div class="status" :class="getStatus(apartment.status)[0]">
          {{ getStatus(apartment.status)[1] }}
        </div>
      </template>
      <template v-else v-slot:image>
        <img src="../../assets/img/house-placeholder.gif" />
        <div class="status" :class="getStatus(apartment.status)[0]">
          {{ getStatus(apartment.status)[1] }}
        </div>
      </template>
      <template v-slot:title> {{ apartment.description }} </template>

      <p style="color:#c33b07">
        {{apartment.investment.type}} - {{apartment.investment.location.address}}, {{apartment.investment.number}} | {{apartment.investment.location.city.name}}
      </p>

      <template v-slot:details>
        <span
          ><b>{{ apartment.surface }} m²</b></span
        >
        <span>{{ rooms(apartment.rooms) }}</span>
        <span>{{ apartment.floor }}</span>
      </template>

      <template v-slot:number>
        {{ apartment.number }}
      </template>

      <template v-slot:price v-if="apartment.status !== 'sold'">
        <span class="price"
          ><fa-icon style="color: darkgreen" icon="tag"></fa-icon>
          {{ apartment.price }}zł
          <small v-if="apartment.price_m2">({{ apartment.price_m2 }}zł / m²)</small></span
        >
      </template>

      <template v-slot:buttons>
        <the-button
          :route="`/apartment/${apartment.id}`"
          small
          tbcType="primary"
          noAnim
          >Oferta</the-button
        >
      </template>
    </the-card>
  </card-container>
</template>

<script>
import CardContainer from "../CardContainer.vue";

export default {
  computed: {
    results() {
      return this.$store.getters["apartments/getFilteredResults"];
    },
  },
  methods: {
    getStatus(status) {
      switch (status) {
        case "free":
          return ["success", "Aktualne"];
        case "reserved":
          return ["warning", "Zarezerwowane"];
        case "sold":
          return ["danger", "Sprzedane"];
      }
    },
    rooms(digit) {
      return `${digit} ${digit < 2 ? 'Pokój' : digit < 5 ? 'Pokoje' : 'Pokoi'}`;
    }
  },
  components: {
    CardContainer,
  },
  inject: ["configBackendUrl", "configBackendHttp"],
};
</script>

<style>
</style>