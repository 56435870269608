<template>
  <div :class="{'active' : isActive}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props:['isActive'],
}
</script>

<style scoped>
  div {
    width: 100%;
    height: auto;
    margin:.5rem;
    cursor: pointer;
  }

  div:first-child,
  div:last-child {
    margin-left: 0;
    margin-right: 0;
  }

  div :deep() img {
    width: 100%;
    min-width: 211px;
    max-height: 150px;
    object-fit: contain;
    height: auto;
    border: 3px solid white;
    object-fit: cover;
  }

  div.active :deep() img {
    border: 3px solid #c33b07;
  }

</style>