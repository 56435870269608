<template>
  <div class="row">
    <div class="col-lg-6 mb-lg-0 mb-4">
      <p>Bądź na bieżąco!</p>
      <p>Zapisz się do naszego newslettera</p>
    </div>
    <transition mode="out-in" name="formComplete">
      <div class="col-lg-6" v-if="!sent">
        <div class="form-group">
          <span class="d-flex align-items-center">
            <fa-icon icon="envelope"></fa-icon>
            <span style="font-size:1.6rem; margin-left:.5rem; width: max-content">E-Mail</span>
          </span>
          <div class="d-flex" style="position: relative; width: 100%">
            <div class="tool_tip" v-if="isInvalidEmail">{{ isInvalidEmail }}</div>
            <input v-model.trim="mail" type="email" @keyup="isInvalidEmail ? isInvalidEmail = false : ''" required>
            <button @click="sendForm()">Zapisz się</button>
          </div>
        </div>
      </div>
      <div class="col-lg-6" v-else>
        <div class="form_success d-flex align-items-center">
          <fa-icon class="icon" icon="check-circle"></fa-icon>
          <p class="ms-2 d-inline">
            Zapisałeś się do Newslettera!
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as setup from '../../setup.js';
export default {
  data() {
    return {
      mail: '',
      isInvalidEmail: false,
      sent: false
    }
  },
  methods: {
    async sendForm() {
      try {
        const isValidEmail = this.validateEmail(this.mail);
        if(!isValidEmail) { throw 'Podano błędny adres e-mail!'; }

        const response = await fetch(`${setup.backendHttp}${setup.backendUrl}/api/newsletter/subscribe`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body:JSON.stringify({
            email: this.mail
          })
        });
        if(response.ok) {
          this.mail = "";
          this.sent = true;
        }
      } catch(e) {
        this.isInvalidEmail = e;
      }
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  }
};
</script>

<style scoped>
p {
  color: #fff;
  font-size: 1.6rem;
}

.form-group {
  border:1px solid #c33b07;
  background:white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}

.form-group > * {
  margin:1rem;
}

.form-group svg {
  font-size: 3rem;
  color: #c33b07;
}

input {
  width: 100%;
  height: 40px;
  position:relative;
  border:none;
  font-size: 1.6rem;
  padding-left: 1rem;
  padding-right: 1rem;
  outline: none;
  font-family: 'Poppins',sans-serif;
}

input:focus {
  box-shadow: inset 0 0 0 3px #c33b07;
}

.form-group button {
  /* position:absolute; */
  right:0;
  display: grid;
  place-items: center;
  background: #c33b07;
  border:none;
  font-family: 'Poppins',sans-serif;
  color:white;
  height: 40px;
  width: 100px;
  cursor: pointer;
}

.form_success .icon {
  color: #3ed339;
  font-size: 4.5rem;
}

.form_success p {
  font-size: 1.8rem;
}

.tool_tip {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  color: #cc0033;
  line-height: 20px;
  
  background-color: #fce4e4;
  border: 1px solid #fcc2c3;
  border-radius: 7px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  
  position: absolute;
  bottom: -45px;
  width: max-content;
  left: calc( 50% - 60px);
  transform: translate(-50%,0);

  padding: 7px 11px 4px;
  margin-left: 10px; 
}

.tool_tip:after, .tool_tip:before {
  content: '';
  border: 7px solid transparent;
  position: absolute;
  left: 50%;
  transform: translate(-50%,0);
}

.tool_tip:after {
  border-bottom: 7px solid #fce4e4;
  top: -14px;
}

.tool_tip:before {
  border-bottom: 7px solid #fcc2c3;
  top: -15px;
}


.formComplete-enter-from,
.formComplete-leave-to {
  opacity: 0;
}

.formComplete-enter-active,
.formComplete-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.formComplete-enter-to,
.formComplete-leave-from {
  opacity: 1;
}
</style>