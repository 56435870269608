<template>
  <transition name="loader">
    <div v-if="show" class="loader" :class="{ danger: exception }">
      <transition name="state" mode="out-in">
        <div v-if="!exception" class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="loader__exception" v-else>
          <fa-icon icon="times"></fa-icon>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["show"],
  computed: {
    exception() {
      return this.$store.getters.getException;
    },
  },
};
</script>

<style scoped>
.loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  left: 20px;
  z-index: 10;
  width: 100px;
  height: 40px;
  border-radius: 2rem;
  background: #c33b07;
  transition: background 1s ease-in;
  overflow: hidden;
}
.loader.danger {
  background: #c30707;
  color: white;
  font-size: 3.2rem;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

.loader-enter-active,
.loader-leave-active {
  transition: top 0.5s ease;
}

.loader-enter-from,
.loader-leave-to {
  top: -60px;
}

.state-enter-active,
.state-leave-active {
  transition: transform 0.5s ease;
}

.state-enter-from,
.state-leave-to {
  transform: translateY(40px);
}
</style>