<template>
  <div class="results">
    <span class="result">Znalezionych wyników: {{ results ? results.length : 0 }}</span>
    <card-container>
      <the-card v-for="result in results" :key="result.name">
        <template v-slot:image>
          <router-link :to="`/investment/${result.id}`">
            <img :src="`${configBackendHttp}${configBackendUrl}/${result.images[0].path}`">
          </router-link>
        </template>
        <template v-slot:title>
          {{ result.name }}
        </template>

        Miasto: {{result.city.name}}<br />
        Ulica: {{result.location.address}} <b>{{result.number}}</b><br />
        <template v-slot:buttons>
          <the-button :route="`/investment/${result.id}`" small tbcType="primary">Przejdź do oferty</the-button>
        </template>
      </the-card>
    </card-container>
  </div>
</template>

<script>
import CardContainer from "../CardContainer";

export default {
  computed: {
    results() {
      return this.$store.getters['selling/getFilteredResults']
    },
  },
  methods: {
    filterResults() {

    }
  },
  components: {
    CardContainer
  },
  inject:['configBackendUrl','configBackendHttp']
}
</script>

<style scoped>
  div.results {
    margin-top:5rem;
    min-height: 500px;
  }
  span.result {
    font-size: 1.6rem;
    color: #6a6a6a;
  }
</style>