<template>
  <main-image :description="mainImageData ? mainImageData.description : ''">
    <!-- <img
      v-if="mainImage"
      :src="`${configBackendHttp}${configBackendUrl}/${mainImageData['path']}`"
      alt=""
      srcset=""
    />
    <img v-else src="../../assets/img/house-placeholder.gif" alt="" srcset="" /> -->
    <img
      :src="mainImageSrc"
      alt=""
      srcset=""
    />
  </main-image>
  <div v-if="images && images.length > 1" class="images-container">
    <fa-icon v-if="images.length > 2" @click="scrollItem(-300)" style="cursor:pointer; font-size:6rem; color:#c33b07; margin-right:1rem" icon="angle-left"></fa-icon>
    <div class="small-images">
      <small-image
        v-for="image in sortedImages"
        :key="image.id"
        :isActive="mainImage == image.id"
        @click="changeMainImage(image.id)"
      >
        <img
          :src="`${configBackendHttp}${configBackendUrl}/${image.path}`"
          srcset=""
        />
      </small-image>
    </div>
    <fa-icon v-if="images.length > 2" @click="scrollItem(300)" style="cursor:pointer; font-size:6rem; color:#c33b07; margin-left:1rem" icon="angle-right"></fa-icon>
  </div>
</template>

<script>
import SmallImage from "./productimage/SmallImage";
import MainImage from "./productimage/MainImage";

export default {
  props: ["images"],
  data() {
    return {
      mainImage: null,
    };
  },
  beforeMount() {
    if (!this.images || this.images.length < 1) return (this.mainImage = null);
    this.mainImage = this.images.find(x => x.main === true).id;
  },
  computed: {
    mainImageData() {
      if (!this.images) return null;
      const mainImage = this.images.find((x) => x.id == this.mainImage);
      return mainImage ? mainImage : null;
    },
    mainImageSrc() {
      if(this.mainImageData) {
        return `${this.configBackendHttp}${this.configBackendUrl}/${this.mainImageData['path']}`
      } else return require("../../assets/img/house-placeholder.gif")
    },
    sortedImages() {
      return [...this.images].sort((x,y) => x.main === y.main ? 0 : x.main ? -1 : 1);
    }
  },
  components: {
    SmallImage,
    MainImage,
  },
  methods: {
    changeMainImage(id) {
      this.mainImage = id;
    },
    scrollItem(amount) {
      const container = document.querySelector('.small-images');
      const maxScroll = container.scrollLeftMax;
      const currentScroll = container.scrollLeft;
      if(amount + currentScroll > maxScroll) container.scrollLeft = maxScroll;
      else container.scrollLeft+=amount;
    }
  },
  inject: ["configBackendUrl", "configBackendHttp"],
  watch: {
    images: function(val) {
      if(!val.length) return this.mainImage = null;
      this.mainImage = val.find(x => x.main === true).id;
    }
  }
};
</script>

<style scoped>
.images-container {
  display: flex;
  align-items: center;
}

.small-images {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

@media (min-width: 992px) {
  .small-images {
    overflow-x: hidden; 
  }
}

</style>