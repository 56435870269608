<template>
  <div class="container">
    <div class="d-none d-md-block">
      <writer class="mt-5" steps="40"
        >Droga do sukcesu jest zawsze w budowie</writer
      >
    </div>
    <div class="row">
      <div class="col-lg-5">
        <contact-form></contact-form>
      </div>
      <div class="col-lg-7">
        <the-map class="mt-5">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2553.6614425130706!2d17.81443331609066!3d50.204858979442236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4711761749e27809%3A0xc05dc85dc143f0de!2s%C5%BBeromskiego%2025%2C%2048-100%20G%C5%82ubczyce%2C%20Polska!5e0!3m2!1spl!2sde!4v1615571195141!5m2!1spl!2sde"
            width="600"
            height="430"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </the-map>
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from '../ContactForm.vue';
import Writer from "../Writer";

export default {
  components: {
    Writer,
    ContactForm,
  },
};
</script>

<style scoped>

span {
  border-left: 5px solid #c33b07;
  padding-left: 1rem;
  font-size: 2.4rem;
}

address {
  font-size: 1.8rem;
  font-weight: 300;
  font-style: normal;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  text-align: right;
}
</style>