<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style>
iframe {
  width: 100%;
  /* border: 3px solid #c33b07 !important; */
}
</style>