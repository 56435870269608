import { createRouter, createWebHistory } from "vue-router";

import HomePage from "./pages/HomePage.vue";
import InvestmentPage from "./pages/InvestmentsPage.vue";
import ApartmentPage from "./pages/ApartmentPage.vue";
import SingleSellingPage from "./pages/SingleSellingPage.vue";
import SingleApartmentPage from "./pages/SingleApartmentPage.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: "home",
      path: "/",
      component: HomePage
    },
    {
      name: "investments",
      path: "/investment",
      component: InvestmentPage
    },
    {
      name: "singleSelling",
      path: "/investment/:investmentId",
      component: SingleSellingPage,
      props: true
    },
    {
      name: "apartments",
      path: "/apartment",
      component: ApartmentPage,
    },
    {
      name: "singleApartment",
      path: "/apartment/:apartmentId",
      component: SingleApartmentPage,
      props: true
    }
  ]
});

export default router;
