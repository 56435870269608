<template>
  <div class="filterCard mb-3">
    <h5>Filtry</h5>
    <div class="content">
      <div class="input-group">
        <label>Typ</label>
        <the-checkbox @checkboxChecked="checkboxChange" checked="true" value="blok"
          >Mieszkanie</the-checkbox
        >
        <the-checkbox @checkboxChecked="checkboxChange" checked="true" value="segment"
          >Segment</the-checkbox
        >
      </div>
      <div class="silder-group">
        <label>Cena</label>
        <veeno v-if="minPrice > 0"
          connect
          name="price"
          @change="rangeChange"
          :step="1000"
          :tooltips="[true, true]"
          :handles="[minPrice, maxPrice]"
          :range="{
            min: minPrice,
            max: maxPrice,
          }"
        />
      </div>
      <div class="silder-group">
        <label>Metraż</label>
        <veeno v-if="minSurface > 0"
          connect
          name="surface"
          @change="rangeChange"
          :step="1"
          :tooltips="[true, true]"
          :handles="[minSurface, maxSurface]"
          :range="{
            min: minSurface,
            max: maxSurface,
          }"
        />
      </div>
      <div class="input-group">
        <label>Piętro</label>
        <Multiselect
          v-model="filters.floor"
          mode="multiple"
          label="test"
          :options="floors"
          placeholder="Wybierz piętra"
          noOptionsText="Lista jest pusta"
        />
        <div class="badges mt-3">
          <div class="badge" v-for="floor in filters.floor" :key="floor">
            {{ floor }}
          </div>
        </div>
      </div>
      <div class="input-group">
        <label>Taras</label>
        <Multiselect
          v-model="filters.terrace"
          label="test"
          :options="{
            1: 'Tak',
            0: 'Nie',
            2: 'Bez znaczenia'
          }"
          placeholder="Mieszkanie z tarasem?"
          noOptionsText="Lista jest pusta"
        />
      </div>
      <div class="input-group">
        <label>Miasta</label>
        <Multiselect v-if="cities && cities.length"
          v-model="filters.cities"
          mode="multiple"
          :options="cities"
          placeholder="Wybierz miasta"
          noOptionsText="Lista jest pusta"
        />
        <div class="badges mt-3">
          <div class="badge" v-for="city in filters.cities" :key="city">
            {{ city }}
          </div>
        </div>
      </div>
      <the-button
        tbcType="primary"
        @click="applyFilters"
        style="border-radius: 0"
        >Zatwiedź</the-button
      >
    </div>
  </div>
</template>

<script>
import veeno from "../../plugins/veeno/veeno";
import "../../plugins/veeno/veeno.min.css";

import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  data() {
    return {
      filters: {
        price: {
          from: 0,
          to: 1000000,
        },
        surface: {
          from: 0,
          to: 1200,
        },
        terrace: undefined,
        type: ['blok','segment'],
        floor: null,
        cities: null,
      },
      floors: [
        "Parter",
        "I Piętro",
        "II Piętro",
        "II Piętro + Poddasze",
        "III Piętro",
        "III Piętro + Poddasze",
        "IV Piętro",
        "IV Piętro + Poddasze",
      ],
    };
  },
  components: {
    veeno,
    Multiselect,
  },
  computed: {
    minPrice() {
      return this.$store.getters['apartments/getFilteredResults'].price_min;
    },
    maxPrice() {
      return this.$store.getters['apartments/getFilteredResults'].price_max;
    },
    minSurface() {
      return Math.floor(this.$store.getters['apartments/getFilteredResults'].surface_min);
    },
    maxSurface() {
      return Math.ceil(this.$store.getters['apartments/getFilteredResults'].surface_max);
    },
    cities() {
      return this.$store.getters["cities/foundResults"].map((x) => x.name);
    }
  },
  methods: {
    checkboxChange(value, checked) {
      if (value == "terrace") {
        this.filters.terrace = checked;
      } else {
        this.filters.type.includes(value)
          ? (this.filters.type = this.filters.type.filter((x) => x != value))
          : this.filters.type.push(value);
      }
    },
    rangeChange(sliderObject) {
      this.filters[sliderObject.name].from =
        Math.round((+sliderObject.values[0] + Number.EPSILON) * 100) / 100;
      this.filters[sliderObject.name].to =
        Math.round((+sliderObject.values[1] + Number.EPSILON) * 100) / 100;
    },
    async applyFilters() {
      document.getElementById('filtered-results').scrollIntoView();
      const query = [];
      if (+this.filters.price.from > 0)
        query.push(`price_from=${encodeURIComponent(this.filters.price.from)}`);
      query.push(`price_to=${encodeURIComponent(this.filters.price.to)}`);
      if (+this.filters.surface.from > 0)
        query.push(
          `surface_from=${encodeURIComponent(this.filters.surface.from)}`
        );
      query.push(`surface_to=${encodeURIComponent(this.filters.surface.to)}`);
      if(this.filters.terrace !== undefined && this.filters.terrace!=2) {
        console.log(this.filters.terrace)
        query.push(`terrace=${encodeURIComponent(!!+this.filters.terrace)}`);
      }
      if (this.filters.floor && this.filters.floor.length) {
        const modifiedFloors = this.filters.floor.map(x => encodeURIComponent(x));
        query.push(
          `floor=${modifiedFloors}`
        );
      }
      if (this.filters.type.length) {
        query.push(`type=${this.filters.type.toString()}`);
      }
      if (this.filters.cities && this.filters.cities.length) {
        const modifiedCities = this.filters.cities.map(x => encodeURIComponent(x));
        query.push(
          `city=${modifiedCities}`
        );
      }
      this.$store.commit('apartments/setFilterLastQuery',query);
      const fetchedQuery = `?${query.join("&")}`;
      await this.$store.dispatch('apartments/loadFilteredResults',fetchedQuery);
    },
  }
};
</script>

<style scoped>
.filterCard {
  background: #fff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
}

.filterCard h5 {
  background: #c33b07;
  color: white;
  padding: 1rem;
  font-size: 1.8rem;
}

.filterCard .content {
  font-size: 1.4rem;
  padding: 1rem;
}

.filterCard .content .silder-group:first-child {
  margin-top: 0;
}
.filterCard .content .silder-group {
  margin-bottom: 5rem;
  padding:0 2.2rem;
}
.filterCard .content .input-group {
  margin-top: 2rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
}

.badges {
  display: flex;
  flex-wrap: wrap;
}

.badge {
  padding: 0.5rem 1rem;
  background: #2133d9;
  color: white;
  border-radius: 7px;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}

/** Styling of no UI */
:deep() .veeno {
  margin-top: 1rem;
}

:deep() .noUi-origin {
  width: 10%;
}

:deep() .noUi-connect {
  background: #c33b07;
}

:deep() .noUi-handle {
  border-radius: 20rem;
  background: #c33b07;
  box-shadow: none;
  border: 1px solid #8a2b06;
}

:deep() .noUi-handle::after,
:deep() .noUi-handle::before {
  display: none;
}

:deep() .noUi-tooltip {
  font-size: 1.3rem;
  bottom: -4rem;
}

:deep() .noUi-horizontal {
  height: 1rem;
}

:deep() .noUi-horizontal .noUi-origin .noUi-handle {
  width: 2rem;
  height: 2rem;
  right: -17px;
  top: -6px;
}

:deep() .noUi-horizontal .noUi-origin:last-child .noUi-handle {
  width: 2rem;
  height: 2rem;
  right: -0.8rem;
  top: -6px;
}

/** Multiselect styling */
:deep() .multiselect-option.is-selected,
:deep() .is-multiple .multiselect-option.is-selected.is-pointed {
  background: #c33b07;
  color: white;
  cursor: pointer;
}

:deep() .multiselect-option.is-pointed,
:deep() .is-tags .multiselect-option.is-selected.is-pointed {
  background: #e0612e;
  color: white;
  cursor: pointer;
}
</style>