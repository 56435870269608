import * as setup from '../../setup'

export default {
  namespaced: true,
  state() {
    return {
      foundResults: []
    };
  },
  getters: {
    foundResults(state) {
      return state.foundResults;
    }
  },
  mutations: {
    setFoundResults(state, payload) {
      state.foundResults = payload;
    }
  },
  actions: {
    async loadResults(context) {
      try {
        context.commit('setIsLoading',{triggeredBy: 'cities'},{root:true});
        if(context.state.foundResults.length>0) {
          return;
        }
        const response = await fetch(`${setup.backendHttp}${setup.backendUrl}/api/cities`);
        const data = await response.json();
        if(!response.ok) throw new Error('There\'s an error');
        context.commit('setFoundResults', data.cities);
      } catch(e) {
        context.commit('setException',{message: e.message},{root:true});
      } finally {
        context.commit('setIsLoading',{triggeredBy: 'cities'},{root:true});
      }
    }
  }
};
