<template>
  <div id="footer">
    <div class="container">
      <address>
        TBC Development<br />
        Tomasz Czyżowicz<br />
        +48 607 926 266
      </address>
      <span>
        <b>Projekt i wykonanie</b><br>
        Kacper Oman<br>
        Kacper Dąbrowa
      </span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#footer {
  background: #1d1d1d;
  color: white;
  width: 100vw;
  height: 100px;
}

#footer .container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#footer .container address {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 300;
}

#footer .container span {
  font-size: 1.3rem;
  font-weight: 300;
  text-align: right;
  border-right: 3px solid #c33b07;
  padding-right: 1rem;
}
#footer .container b {
  font-size: 1.6rem;
  font-weight: 700;
}
</style>