<template>
  <div class="card" :class="classSetter">
    <slot name="image">
    </slot>
    <div class="content">
      <h4 v-if="$slots.title">
        <slot name="title"></slot>
      </h4>
      <p>
        <slot></slot>
      </p>
      <div class="d-flex details justify-content-stretch align-items-center" v-if="$slots.details">
        <slot name="details"></slot>
      </div>
      <div class="cardDownside mt-4 d-flex justify-content-between align-items-center" v-if="$slots.buttons">
        <slot name="price" v-if="$slots.price"></slot>
        <slot name="buttons"></slot>
      </div>
      <div class="number">
        <slot name="number"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['flexColumn','centerText','forcewidth','five'],
  computed: {
    classSetter() {
      return {
        'forceWidth' : this.forcewidth !== undefined,
        'flex' : !!this.flexColumn,
        'centered' : !!this.centerText,
        'five': !!this.five
      }
    }
  }
}
</script>

<style scoped>
.card {
  position:relative;
  max-width: 100%;
  width: 100%;
  min-width: 300px;
  height: auto;
  background: #fff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  display: inline-block;
  margin-top:2rem;
  margin-left: auto;
  margin-right: auto;
  border-left: 3px solid #c33b07;
  position:relative;
  border-bottom-left-radius: 3rem;
}

.card.five {
  min-width: 245px;
}

.card.forceWidth {
  width: 100% !important;
  min-width: unset;
  max-width: unset;
}

.card .content .details{
  margin-top:2rem;
  font-size: 1.4rem;
  height: 50px;
  justify-content: space-between;
}

.card .content .details :deep() span{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-left: 3px solid #c33b07;
}
.card .content .details :deep() span:first-child{
  border-left:none;
}

.card :deep() .price {
  font-size: 1.5rem;
  font-weight: 400;
  color: black;
  background: transparent;
}

.card.flex .content p{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card.centered .content p{
  text-align: center;
}

.card .number {
  position:absolute;
  top:10px;
  left:10px;
  color: #c33b07;
  font-size: 1.3rem;
  font-weight: bold;
}

.card :deep() img:not(.c-icon){
  width: 100%;
  object-fit: cover;
  height: auto;
  height: 250px;
  /* border-top: 3px solid #c33b07;
  border-right: 3px solid #c33b07;
  border-bottom: 3px solid #c33b07; */
}

.card :deep() .status {
  position:absolute;
  top:225px;
  right:0px;
  background: #c33b07;
  padding:.8rem 2rem;
  font-size: 1.3rem;
  font-weight: 300;
  color:white;
}

.card :deep() .status.success {
  background: #298b0b;
  color:white;
}

.card :deep() .status.warning {
  background: #8b580b;
  color:white;
}

.card :deep() .status.danger {
  background: #8b0b0b;
  color:white;
}

.content {
  padding:1rem;
}

h4 {
  font-weight: bold;
  color: black;
  font-size: 1.6rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  /* height: 5rem; */
}

p {
  font-size: 1.4rem;
  letter-spacing: .05em;
  margin-bottom: 1rem;
}

@media (min-width: 992px) {
  .card.forceWidth {
    width: 31.8% !important;
  }
}

</style>