<template>
  <div class="card-container justify-content-lg-start justify-content-center" :class="{'five': five !== undefined}">
    <slot></slot>
  </div>
</template>

<script>

export default {
  props: ['five'],
};
</script>

<style scoped>
/*.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 2.2rem;
  align-items: stretch;
  justify-items: stretch;
  margin-bottom:4rem;
}*/

.card-container {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom:4rem;
  flex-wrap: wrap;
  margin: -1rem;
}

.card-container :deep() .card {
  flex: 0 1 23.3%;
  margin:1rem;
  max-width: 400px;
}

.card-container.five :deep() .card {
  flex: 0 1 18.5%;
}

.card-container.grow :deep() .card {
  flex-grow: 1;
}

@media (min-width: 1400px) {
  .card {
    min-width: 300px;
  }
}
</style>