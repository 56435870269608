<template>
  <div>
    <the-section title="Inwestycje" container>
      <steps></steps>
      <results></results>
    </the-section>
  </div>
</template>

<script>
import Steps from '../components/sellingpage/Steps';
import Results from '../components/sellingpage/Results';

export default {
  components: {
    Steps,
    Results
  },
  mounted() {
    this.$store.dispatch('cities/loadResults');
    this.$store.dispatch('selling/loadResults');
  },
  
}
</script>

<style scoped>

</style>