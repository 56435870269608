<template>
  <div class="flex-this">
    <div class="stepCircle">{{step}}</div>
    <div><slot></slot></div>
    <slot name="result"></slot>
  </div>
</template>

<script>
export default {
  props: ['step']
};
</script>

<style scoped>
.flex-this {
  display: flex;
  align-items: center;
  height: 100%;
  margin-top:1.5rem;
}

.flex-this:first-child {
  margin-top:0;
}

.flex-this div {
  margin-left:1rem;
  font-size: 1.6rem;
}

.stepCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  color: white;
  background: #c33b07;
  font-size: 2.4rem;
  border-radius: 50%;
  margin-right: 1rem;
}

:deep() span {
  font-size: 2.4rem;
  margin-left:5rem;
}

:deep() input[type=checkbox] {
  width:2rem;
  height: 2rem;
}
</style>